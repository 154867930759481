.main-view {
  display: flex;
  /* width: 98.7vw; */
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding-top: 50px;
  padding-left: 5px;
  padding-right: 5px;
  flex-wrap: wrap;
  /* height: 90.1%; */
  /* position: absolute; */
}

.main-left {
  flex: 1;
  width: 50%;
}

.main-rgiht {
  flex: 1;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 90%;
  height: 70vh;
  box-shadow: 1px 1px 3px 6px rgb(230, 229, 229);
  border: 4px solid rgb(230, 229, 229);
  border-radius: 20px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  /* aspect-ratio: 16/9; */
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.main-head {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0px 100px;
}

.text-con-head {
  text-align: center;
  font-size: 32px;
  color: #000;
  font-weight: 600;
  margin: 0 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.con-text {
  font-size: 22px;
  color: #000;
  font-weight: 400;
  align-items: center;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.form-item-input {
  width: 300px;
  height: 40px;
  padding: 10px 10px;
  box-shadow: 1px 1px 1px 3px #d7d7d7;
}
.form-item-input:hover {
  outline: none;
}
.form-item-place {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.already-login {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-spf {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.al-sign-spf {
  text-decoration: none !important;
  color: #0cc0df;
  cursor: pointer;
}

.code-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ducument-class {
  background-color: #ffffff;
  margin-top: 40px;
}

.doc-head {
  padding-top: 40px;
  text-align: center;
  font-weight: 800;
}

.code-edit {
  background-color: #0f0f0f;
  border-radius: 12px;
  width: 40%;
  padding: 10px;
  line-height: 2px;
  font-size: 14px;
  font-weight: 500;
}
.step1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  gap: 15px;
  position: relative;
}
.step-span {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.copy-icon {
  position: absolute;
  top: 10%;
  right: 5%;
  color: #fff;
}
.eye-icon {
  position: absolute;
  top: 10%;
  right: 10%;
  color: #fff;
}
.step-text {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#map-container,
#left,
#right,
.map,
.gutter {
  height: 80vh;
  margin: 0 !important;
  padding: 0 !important;
}

#map-container {
  width: 95vw;
}

#left,
#right,
.gutter {
  float: left;
  position: relative;
  overflow: hidden;
}

#map-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 80vh;
  width: 4000px;
}

#map-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 80vh;
  width: 4000px;
}

.gutter {
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: 50%;
  width: inherit;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
}

.map-zoom {
  display: flex;
  width: 29px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  bottom: 20%;
  right: 10%;
}

.zoom-btn {
  display: flex;
  width: 29px;
  height: 29px;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
}

.zoom-btn-out {
  border-top: 1px solid #ddd;
}

.map-filter {
  position: absolute !important;
  bottom: 2%;
  left: 1%;
  z-index: 999;
  border-radius: 10px;
  background: var(--neutral-09, #1f2937);
  max-width: 430px;
  width: auto;
}
.filter-container {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  width: inherit;
  padding: 10px;
}
.filter-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
}
.filter-head span {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.07px;
}
.filter-head img {
  cursor: pointer;
}

.filter-item {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
}
.filter-item-active {
  border-radius: 6px;
  background: #fed7aa;
}
.filter-item-active-food {
  border-radius: 6px;
  background: #d9f99d;
}
.filter-item-active-landmark {
  border-radius: 6px;
  background: #ff0048;
}
.filter-item-active-hub {
  border-radius: 6px;
  background: #bfdbfe;
}

.filter-item img {
  border-radius: 3px;
  background: #bfdbfe;
}
.filter-item-active img {
  background-color: #ffffff00 !important;
}

.filter-text {
  color: #bfdbfe;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.06px;
}

.filter-item-active span {
  color: #000 !important;
}

.img-rotate {
  transform: rotate(180deg);
}

#map-container {
  box-shadow: 1px 1px 5px 3px #a9a9a9;
  border-radius: 12px;
  overflow: hidden;
}
